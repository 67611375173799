import React from 'react';
import './Terms.css';

const Terms = () => {
  return (
    <div className="terms-container">
      <h1>Our Terms and conditions</h1>
      <ol>
        <li>We register all types of relationships. The profile of your preferred partner will be sent to you via the "Rishta Junction" office and on your WhatsApp. The profile will not include the partner's address or phone number.</li>
        <li>After registration, when a suitable match is available, the "Rishta Junction" team will send you profiles from time to time. There is no time limit for arranging a match.</li>
        <li>A registration fee must be paid at the time of registration; only then will your form be registered. The registration will be valid for a period of three months.</li>
        <li>The registration fee for "Rishta Junction" can be deposited online or in cash.</li>
        <li>The registration fee is non-refundable. Once paid, it will not be returned under any circumstances.</li>
        <li>It is our duty to provide you with a suitable and good match. We wish you a prosperous future.</li>
        <li>The information provided by us is based on the biodata of the other party. You must review and verify it to your satisfaction.</li>
        <li>The information you provide or fill in the form must be correct. If found inaccurate, your form will be canceled.</li>
        <li>Please review our rules before filling out the registration form. Register only if you are satisfied.</li>
        <li>Any disputes will be subject to the jurisdiction of the Aligarh court.</li>
        <li>If any issues regarding dowry or family financial arrangements arise before or after marriage, you will be solely responsible. "Rishta Junction" and its team will not bear any legal or social responsibility in such matters.</li>
      </ol>
      <p><strong>Terms and Conditions</strong></p>
    </div>
  );
};

export default Terms;
